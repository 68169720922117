import { Colors } from '@/styles/colors';
import {
  FontFamily,
  FontLineHeight,
  FontSize,
  FontWeight,
} from '@/styles/tokens';
import { css } from '@emotion/css';

export interface TypographyStyle {
  color?: Colors;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  fontFamily?: FontFamily;
  lineHeight?: FontLineHeight;
  letterSpacing?: string;
}

export const typography = ({
  color,
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  letterSpacing,
}: TypographyStyle) =>
  css({
    color,
    fontSize,
    fontWeight,
    fontFamily,
    lineHeight,
    letterSpacing,
    padding: 0,
  });
