import { H3, Paragraph } from '@/components/Typography';
import Navbar from '@/landing/components/navigation/Navbar';
import Footer from '@/landing/components/sections/Footer/Footer';
import styles from '@/landing/css/404.module.css';
import withApollo, {
  createSsrApolloClient,
} from '@/lib/apollo-graphql/withApollo';
import { authUser } from '@/utils/auth';
import { UserEntity } from '@nerdbord/graphql-api';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

interface Page404Props {
  user: UserEntity | null;
}

function Page404({ user }: Page404Props) {
  return (
    <>
      <Head>
        <title>Nerdbord - page not found</title>
        <meta
          name="description"
          content="We turn Javascript coders into software engineers. "
        />
        <meta property="og:title" content="Nerdbord" />
        <meta
          property="og:description"
          content="We turn Javascript coders into software engineers. "
        />
        <meta
          property="og:image"
          content="./static/img/Sharing.webp"
        />
        <meta
          property="og:image:alt"
          content="Learn JavaScript Engineering with AI mentor on demand"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <link rel="icon" href="/favicon/favicon.ico" />
      </Head>
      <Navbar user={user} />
      <div className={styles.wrapper}>
        <H3>Resource not found</H3>
        <Paragraph>Sorry, but nothing is here!</Paragraph>
      </div>
      <Footer />
    </>
  );
}

export async function getStaticProps({
  locale,
  ...context
}: GetServerSidePropsContext) {
  const apolloClient = createSsrApolloClient(context);

  const user = await authUser(context, apolloClient);

  return {
    props: {
      user,
      ...(locale &&
        (await serverSideTranslations(locale, ['homepage']))),
    },
  };
}

export default withApollo(Page404);
