import { Typography } from '@/components/Typography/Typography';
import { HeaderProps } from '@/components/Typography/typography-elements/types';
import { Colors } from '@/styles/colors';
import { FontFamily, FontSize, FontWeight } from '@/styles/tokens';
import { PropsWithChildren } from 'react';

export const H3 = ({
  color,
  children,
}: PropsWithChildren<HeaderProps>) => (
  <Typography
    Element={'h3'}
    color={color || Colors.gray800}
    fontFamily={FontFamily.Sora}
    fontWeight={FontWeight.S700}
    // lineHeight={FontLineHeight.H42}
    fontSize={FontSize.S24}
  >
    {children}
  </Typography>
);
