import { PropsWithChildren, useMemo } from 'react';
import { typography, TypographyStyle } from './Typography.styles';

interface TypographyProps extends TypographyStyle {
  Element: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

export const Typography = ({
  color,
  fontWeight,
  fontSize,
  Element,
  fontFamily,
  lineHeight,
  letterSpacing,
  children,
}: PropsWithChildren<TypographyProps>) => {
  const typographyStyles = useMemo(
    () =>
      typography({
        color,
        fontSize,
        fontWeight,
        letterSpacing,
        fontFamily,
        lineHeight,
      }),
    [color]
  );
  return <Element className={typographyStyles}>{children}</Element>;
};
