import { Typography } from '@/components/Typography/Typography';
import { HeaderProps } from '@/components/Typography/typography-elements/types';
import { Colors } from '@/styles/colors';
import {
  FontFamily,
  FontLineHeight,
  FontSize,
  FontWeight,
} from '@/styles/tokens';
import { PropsWithChildren } from 'react';

export const Paragraph = ({
  color,
  children,
}: PropsWithChildren<HeaderProps>) => (
  <Typography
    Element={'p'}
    lineHeight={FontLineHeight.H21}
    color={color || Colors.gray800}
    fontFamily={FontFamily.Inter}
    fontWeight={FontWeight.S400}
    fontSize={FontSize.S14}
  >
    {children}
  </Typography>
);
